<!-- 秒表计时 -->
<template>
  <div>
    <div style="height: 3rem"></div>
    <div class="box_css">
      <div class="title_css">秒表计时</div>
      <div v-if="numberPeopleShow">
        <div class="timing_box_css">
          <div class="timing_css">
            <div
              class="numberPeople_box_css"
              @click="clickPMinutes('-', 'minutes')"
              :class="{ grey_Css: minutes <= 0 }"
            >
              <i class="el-icon-minus"></i>
            </div>
            <input
              type="number"
              v-model="minutes"
              oninput="if(value<=0)value=0;if(value>=500)value=500;value=parseInt(value)"
            />
            <div
              class="numberPeople_box_css"
              @click="clickPMinutes('+', 'minutes')"
              :class="{ grey_Css: minutes >= 500 }"
            >
              <i class="el-icon-plus"></i>
            </div>
            <div class="timing_css_text">分</div>
            <div
              class="numberPeople_box_css"
              @click="clickPeople('-', 'second')"
              :class="{ grey_Css: second <= 0 }"
            >
              <i class="el-icon-minus"></i>
            </div>
            <input
              type="number"
              v-model="second"
              oninput="if(value<=0)value=0;if(value>=59)value=59;value=parseInt(value)"
            />
            <div
              class="numberPeople_box_css"
              @click="clickPeople('+', 'second')"
              :class="{ grey_Css: second >= 59 }"
            >
              <i class="el-icon-plus"></i>
            </div>
            <div class="timing_css_text">秒</div>
          </div>
          <div class="checkMusic" v-if="!pour">
            <el-checkbox
              v-if="minutes >= 5"
              v-model="checkFive"
              label="剩余5分钟播报"
              size="large"
            />
            <el-checkbox
              v-if="minutes >= 10"
              v-model="checkTen"
              label="剩余10分钟播报"
              size="large"
            />
          </div>
        </div>
        <div class="zhengfan_css">
          <div :class="{ zhengfan_hover_css: pour }" @click="pour = true">
            正
          </div>
          <div
            :class="{ zhengfan_hover_css: !pour }"
            @click="pour = false"
            style="margin-left: 80px;"
          >
            倒
          </div>
        </div>
        <div class="rollCall_bottom_css">
          <div @click="clickSelect()">开始计时</div>
        </div>
      </div>
      <div v-if="randomShow">
        <div class="random_css" :class="{ redColor: setTime < 11 && !pour }">
          {{ getTime(setTime) }}
        </div>
        <div
          class="randomShow_bottom_css"
          v-if="(setTime >= 0 && !pour) || (setTime <= setTimeA && pour)"
        >
          <div @click="clickStop()">停止计时</div>
          <div @click="clickPause()">{{ TimeFlag ? '继续' : '暂停' }}</div>
        </div>
        <div class="rollCall_bottom_css" v-else>
          <div @click="clickStop()">返回</div>
        </div>
      </div>
    </div>
    <!-- 已结束 -->
    <audio id="zeroAudio" style="display: none;">
      <source src="@/assets/music/zero.mp3" type="audio/mpeg" />
    </audio>
    <!-- 5分钟 -->
    <audio id="fiveAudio" style="display: none;">
      <source src="@/assets/music/five.mp3" type="audio/mpeg" />
    </audio>
    <!-- 10分钟 -->
    <audio id="tenAudio" style="display: none;">
      <source src="@/assets/music/ten.mp3" type="audio/mpeg" />
    </audio>
  </div>
</template>
<script>
let funSetTime = null
export default {
  data() {
    return {
      numberPeopleShow: true,
      randomShow: false,
      checkFive: false, //5分钟播报
      checkTen: false, //10分钟播报
      pour: false, // 正计时还是倒计时
      minutes: 5, // 分钟
      second: 0, // 秒钟
      setTime: 0,
      setTimeA: 0,
      TimeFlag: false, // 是否暂停
    }
  },
  beforeDestroy() {
    if (funSetTime) {
      clearInterval(funSetTime)
      funSetTime = null
    }
  },
  methods: {
    // 开始计时
    clickSelect() {
      this.setTime = Number(this.minutes * 60) + Number(this.second)
      this.setTimeA = Number(this.minutes * 60) + Number(this.second)
      if (this.setTime == 0) {
        this.$message.error('倒计时不可为0秒')
        return
      }
      if (this.pour) {
        this.setTime = 0
      }
      this.numberPeopleShow = false
      this.randomShow = true

      funSetTime = setInterval(() => {
        if (!this.TimeFlag) {
          if (!this.pour) {
            this.setTime -= 1
            if (this.setTime < 0) {
              clearInterval(funSetTime)
              funSetTime = null
            }
          } else {
            this.setTime += 1
            if (this.setTime > this.setTimeA) {
              clearInterval(funSetTime)
              funSetTime = null
            }
          }
        }
      }, 1000)
    },
    getTime(time) {
      //
      if (!this.pour) {
        if (time < 0) {
          // 获取音频元素
          const audioZero1 = document.getElementById('zeroAudio')
          // 播放音频
          audioZero1.play()
          return '时间到'
        } else {
          if (time == 300 && this.checkFive) {
            // 获取音频元素
            const audioFive = document.getElementById('fiveAudio')
            // 播放音频
            audioFive.play()
          }
          if (time == 600 && this.checkTen) {
            // 获取音频元素
            const audioTen = document.getElementById('tenAudio')
            // 播放音频
            audioTen.play()
          }
        }
      } else {
        if (time > this.setTimeA) {
          // 获取音频元素
          const audioZero2 = document.getElementById('zeroAudio')
          // 播放音频
          audioZero2.play()
          return '时间到'
        }
      }

      if (time < 60) {
        let ss = time < 10 ? '0' + time : time
        return '00:' + ss
      } else {
        let ssn = time % 60
        let ss = ssn < 10 ? '0' + ssn : ssn
        let mmn = Math.trunc(time / 60)
        let mm = mmn < 10 ? '0' + mmn : mmn
        return mm + ':' + ss
      }
    },
    clickPause() {
      this.TimeFlag = !this.TimeFlag
    },
    clickStop() {
      this.numberPeopleShow = true
      this.randomShow = false
      this.TimeFlag = false
      clearInterval(funSetTime)
      funSetTime = null
      this.setTime = 0
    },
    //秒数加减
    clickPeople(e, w) {
      if (e == '+') {
        if (this[w] < 59) {
          this[w]++
        }
      } else if (e == '-') {
        if (this[w] > 0) {
          this[w]--
        }
      }
    },
    //分钟加减
    clickPMinutes(e, w) {
      if (e == '+') {
        if (this[w] < 500) {
          this[w]++
        }
      } else if (e == '-') {
        if (this[w] > 0) {
          this[w]--
        }
      }
    },
  },
}
</script>
<style scoped>
/* 在Chrome浏览器下 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* 在Firefox浏览器下 */
input[type='number'] {
  -moz-appearance: textfield;
}
.box_css {
  width: 1200px;
  height: calc(100vh - 230px);
  margin: 0 auto;
  background-color: #ffffffda;
  border-radius: 25px;
}
.title_css {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  padding: 40px 0;
}
.rollCall_bottom_css {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  cursor: pointer;
}
.rollCall_bottom_css > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  width: 450px;
  background-color: #59a6f6;
  color: #fff;
  font-size: 20px;
  border-radius: 1000px;
  box-shadow: 0px 0px 15px #9ec0f6;
}
.randomShow_bottom_css {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}
.randomShow_bottom_css > div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  cursor: pointer;
}
.randomShow_bottom_css > div:nth-child(1) {
  width: 200px;
  border: #59a6f6 1px solid;
  color: #59a6f6;
  font-size: 20px;
  border-radius: 1000px;
  margin-right: 20px;
}
.randomShow_bottom_css > div:nth-child(2) {
  width: 350px;
  background-color: #59a6f6;
  color: #fff;
  font-size: 20px;
  border-radius: 1000px;
  box-shadow: 0px 0px 15px #9ec0f6;
}
.random_css {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 260px;
  font-weight: bold;
  color: #58a6f3;
  height: 375px;
}
.redColor {
  color: #f52424 !important;
}
.timing_box_css {
  width: fit-content;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.timing_css {
  height: 70px;
  border-radius: 10px;
  overflow: hidden;
  border: #dcdfe6 1px solid;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkMusic {
  width: 100%;
  height: 30px;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.timing_css_text {
  background-color: #f5f7fa;
  height: 100%;
  font-size: 30px;
  color: #b0b0b4d3;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: #dcdfe6 1px solid;
  border-right: #dcdfe6 1px solid;
}
.timing_css_text:nth-last-child(1) {
  border-right: none;
}
.timing_css > input {
  outline: none;
  height: 100%;
  font-size: 30px;
  width: 70px;
  text-align: center;
}

.zhengfan_css {
  height: 170px;
  display: flex;
  justify-content: center;
}
.zhengfan_css > div {
  width: 120px;
  height: 120px;
  border-radius: 14px;
  line-height: 120px;
  text-align: center;
  color: #929191;
  font-size: 50px;
  background-color: #bababb9a;
  cursor: pointer;
}
.zhengfan_hover_css {
  color: #fff !important;
  background-color: #58a6f3 !important;
}
.numberPeople_box_css {
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.numberPeople_box_css > i {
  font-size: 32px;
  color: #5a94e6;
  font-weight: bold;
}
/* .grey_Css {
  background-color: #f8f8f8 !important;
} */
.grey_Css > i {
  color: #c7c7c7 !important;
}
</style>
